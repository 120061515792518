import React from "react";
import { useState, useEffect } from "react";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import FormHelperText from "@mui/material/FormHelperText";

// Argon Dashboard 2 PRO MUI components
import DashboardLayout from "blikol-layouts/components/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

// Settings page components
import FormField from "layouts/pages/users/new-user/components/FormField";

//routes
import { useNavigate, useLocation } from "react-router-dom";

//validation
import { Formik, Form } from "formik";
import * as Yup from "yup";

import axios from "axios";
//external components
import "react-country-state-city/dist/react-country-state-city.css";
import useClinicService from "service-hooks/clinic/useClinicService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import {
    GetCountries,
    GetState,
    GetCity, //async functions
} from "react-country-state-city";
import ArgonSelect from "components/ArgonSelect";
import { setUser } from "slices/authSlice";
import CustomCreateableSelect from "components/CustomCreateableSelect";
import "utils/i18n"
import { useTranslation } from "react-i18next";
import { languageOptions } from "parsers/formatParser";
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import "./style.css"

function profile() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userRole = useSelector((state) => state.auth?.user?.role);
    const clinicData = useSelector((state) => state.auth?.user);
    const clinicHookService = useClinicService();
    const [countryid, setCountryid] = useState(0);
    const [stateId, setstateId] = useState(0);
    const [cityId, setcityId] = useState(0);
    const [countriesList, setCountriesList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [initialValues, setInitialValues] = useState(clinicData);
    const [phoneNumberError, setPhoneNumberError] = useState(false)
    const { t, i18n } = useTranslation()
    const validationSchema = Yup.object({
        name: Yup.string().required(t("Clinic Name is required")),
        email: Yup.string().email(t("Invalid email address")).required(t("Clinic Email is required")),
        phone_number: Yup.string()
            .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, t("Invalid phone number"))
            .required(t("Phone number is required")),
        address1: Yup.string().required(t("Address1 is required")),
        country: Yup.string().required(t("Country is required")),
        state: Yup.string().required(t("State is required")),
        city: Yup.string().required(t("City is required")),
        postal_code: Yup.string().required(t("Postal code is required")),
        _language: Yup.string().required("Language is required"),
    });

    const handleSubmit = (values) => {
        if (clinicData) {
            clinicHookService.update(values, clinicData?.id).then((result) => {
                if (result) {
                    dispatch(setUser(result.data));
                    i18n.changeLanguage(result?.data?._language)
                    setInitialValues(result.data)
                    toast.success(t("Clinic updated successfully"));
                    navigate(`/${userRole}/dashboard`);
                }
            });
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await GetCountries();
                setCountriesList(response);
            } catch (e) {
                console.log("there seems to be an error", e);
            }
        };
        fetchData();
    }, []);

    const countryChange = async (value) => {
        try {
            setCountryid(value);
            const response = await GetState(value);
            response.length !== 0 ? setStateList(response) : setStateList(null);
            setCityList(null);
            setcityId("");
            setstateId("");
        } catch (e) {
            console.log("there seems to be an error", e);
        }
    };

    const stateChange = async (value) => {
        try {
            setstateId(value);
            const response = await GetCity(countryid, value);
            response.length !== 0 ? setCityList(response) : setCityList(null);
        } catch (e) {
            console.log("there seems to be an error", e);
        }
    };

    return (
        <DashboardLayout colorBgHeight={"110px"}>
            <DashboardNavbar customRoutePath={t("Profile")} />
            <>
                <ArgonBox px={3} pt={3}>
                    <ArgonTypography
                        sx={{
                            color: "var(--Default, #172B4D)",
                            fontSize: "25px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "137%",
                            letterSpacing: "-0.8px",
                        }}
                    >
                        {t("Profile")}
                    </ArgonTypography>
                </ArgonBox>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => handleSubmit(values)}
                >
                    {(props) => (
                        <Form>
                            <Card
                                id="clinic-info"
                                sx={{
                                    overflow: "visible",
                                    borderRadius: "20px",
                                    margin: "20px 20px 40px 20px",
                                    boxShadow: "0px 5px 14px 0px rgba(0, 0, 0, 0.05)",
                                }}
                            >
                                <ArgonBox p={3}>
                                    <ArgonTypography variant="h5">{t("Clinic Info")}</ArgonTypography>
                                </ArgonBox>
                                <ArgonBox pb={3} px={3}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <FormField
                                                label={t("clinic name")}
                                                placeholder="Alec Benjamin"
                                                value={props.values.name}
                                                name="name"
                                                error={props.errors.name && props.touched?.name}
                                                onChange={props.handleChange}
                                                requiredField={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormField
                                                label={t("clinic email")}
                                                placeholder="example@email.com"
                                                name="email"
                                                value={props.values.email}
                                                error={props.errors.email && props.touched?.email}
                                                onChange={props.handleChange}
                                                readOnly={true}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                <ArgonTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize"
                                                >
                                                    <>
                                                        {t("Phone Number")}
                                                        <span style={{ color: 'red', marginLeft: "3px" }}>*</span>
                                                    </>
                                                </ArgonTypography>
                                            </ArgonBox>
                                            <PhoneInput
                                                name="patient-phone-number"
                                                international
                                                value={props.values.phone_number}
                                                onChange={(e) => {
                                                    props.setFieldValue("phone_number", e);
                                                    if (e === undefined || isValidPhoneNumber(e)) {
                                                        setPhoneNumberError(false);
                                                        props.setFieldError("phone_number", undefined);
                                                    } else {
                                                        setPhoneNumberError(true);
                                                        props.setFieldError("phone_number", t("Phone number is invalid"));
                                                    }
                                                }}
                                                className={(phoneNumberError) || (props?.touched?.phone_number && props?.errors?.phone_number) ? "error-phone-number " : "PhoneInput"}
                                                rules={{ required: true }}
                                            />
                                            {((props?.touched?.phone_number && props?.errors?.phone_number) || phoneNumberError) && (
                                                <ArgonBox mt={0.75}>
                                                    <ArgonTypography component="div" variant="caption" color="error">
                                                        {props.values.phone_number === '' ? t("Phone number is required") : t("Phone number is invalid")}
                                                    </ArgonTypography>
                                                </ArgonBox>
                                            )}
                                        </Grid>                                    </Grid>
                                </ArgonBox>
                            </Card>
                            <Card
                                id="clinic address"
                                sx={{
                                    overflow: "visible",
                                    borderRadius: "20px",
                                    margin: "20px 20px 40px 20px",
                                    boxShadow: "0px 5px 14px 0px rgba(0, 0, 0, 0.05)",
                                }}
                            >
                                <ArgonBox p={3}>
                                    <ArgonTypography variant="h5">{t("Clinic Address")}</ArgonTypography>
                                </ArgonBox>
                                <ArgonBox pb={3} px={3}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <FormField
                                                label={t("address")}
                                                placeholder="eg. Street 120"
                                                value={props.values.address1}
                                                name="address1"
                                                error={props.errors.address1 && props.touched?.address1}
                                                onChange={props.handleChange}
                                                requiredField={true}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={4} pt={1.5} mb={1.5}>
                                        <Grid item xs={12} sm={3}>
                                            <ArgonBox ml={0.5} display="inline-block">
                                                <ArgonTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize"
                                                >
                                                    {t("Country")} <span style={{ color: "red" }}>*</span>
                                                </ArgonTypography>
                                            </ArgonBox>
                                            <ArgonSelect
                                                options={countriesList.map((option) => ({
                                                    label: option.name,
                                                    value: option.id,
                                                }))}
                                                placeholder="Select Country"
                                                defaultValue={
                                                    props.values.country
                                                        ? { label: props.values.country, value: props.values.country }
                                                        : null
                                                }
                                                onChange={(selectedOption) => {
                                                    props.setFieldValue(
                                                        "country",
                                                        selectedOption ? selectedOption.label : null
                                                    );
                                                    props.setFieldValue("state", null);
                                                    props.setFieldValue("city", null);
                                                    setStateList(null);
                                                    countryChange(selectedOption.value);
                                                }}
                                                error={props.errors.country && props.touched?.country}
                                            />
                                            {props.errors.country && props.touched?.country && (
                                                <FormHelperText sx={{ color: "#f5365c", fontSize: "12px", marginTop: "6px" }}>
                                                    {props.errors.country}
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <ArgonBox ml={0.5} display="inline-block">
                                                <ArgonTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize"
                                                >
                                                    {t("State/Province")} <span style={{ color: "red" }}>*</span>
                                                </ArgonTypography>
                                            </ArgonBox>
                                            {stateList !== null ? (
                                                <ArgonSelect
                                                    options={stateList.map((option) => ({
                                                        label: option.name,
                                                        value: option.id,
                                                    }))}
                                                    defaultValue={
                                                        props.values.state
                                                            ? { label: props.values.state, value: props.values.state }
                                                            : null
                                                    }
                                                    onChange={async (selectedOption) => {
                                                        props.setFieldValue(
                                                            "state",
                                                            selectedOption ? selectedOption.label : null
                                                        );
                                                        stateChange(selectedOption.value);
                                                        props.setFieldValue("city", null);
                                                        setCityList(null);
                                                    }}
                                                    error={props.errors.state && props.touched?.state}
                                                    placeholder="Select State"
                                                />
                                            ) : (
                                                <ArgonInput
                                                    defaultValue={props.values.state}
                                                    onChange={(e) => {
                                                        props.setFieldValue("state", e.target.value);
                                                        props.setFieldValue("city", null);
                                                    }}
                                                    placeholder="e.g Punjab"
                                                    error={props.errors.state && props.touched?.state}
                                                ></ArgonInput>
                                            )}
                                            {props.errors.state && props.touched?.state && (
                                                <FormHelperText sx={{ color: "#f5365c", fontSize: "12px", marginTop: "6px" }}>
                                                    {props.errors.state}
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <ArgonBox ml={0.5} display="inline-block">
                                                <ArgonTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize"
                                                    mb={1}
                                                >
                                                    {t("City")} <span style={{ color: "red" }}>*</span>
                                                </ArgonTypography>
                                            </ArgonBox>
                                            {cityList !== null ? (
                                                <CustomCreateableSelect
                                                    options={cityList.map((option) => ({
                                                        label: option.name,
                                                        value: option.id,
                                                    }))}
                                                    onChange={(selectedOption) => {
                                                        props.setFieldValue("city", selectedOption ? selectedOption.label : null);
                                                    }}
                                                    error={props.errors.city && props.touched?.city}
                                                    defaultValue={
                                                        props.values.city
                                                            ? { label: props.values.city, value: props.values.city }
                                                            : null
                                                    }
                                                    placeholder="Select City"
                                                />
                                            ) : (
                                                <ArgonInput
                                                    placeholder="e.g Lahore"
                                                    error={props.errors.city && props.touched?.city}
                                                    onChange={(e) => {
                                                        props.setFieldValue("city", e.target.value);
                                                    }}
                                                    defaultValue={props.values.city}
                                                ></ArgonInput>
                                            )}
                                            {props.errors.city && props.touched?.city && (
                                                <FormHelperText sx={{ color: "#f5365c", fontSize: "12px", marginTop: "6px" }}>
                                                    {props.errors.city}
                                                </FormHelperText>
                                            )}
                                        </Grid>

                                        <Grid item xs={12} sm={3}>
                                            <FormField
                                                label={t("postal code")}
                                                placeholder="eg. 54770"
                                                value={props.values.postal_code}
                                                name="postal_code"
                                                error={props.errors.postal_code && props.touched?.postal_code}
                                                onChange={props.handleChange}
                                                requiredField={true}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={3}>
                                            <ArgonTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                                mb={1}
                                                ml={0.5}
                                            >
                                                Language  <span style={{ color: "red" }}>*</span>
                                            </ArgonTypography>
                                            <ArgonSelect
                                                placeholder="e.g English"
                                                options={languageOptions}
                                                onChange={(selectedOption) => {
                                                    props.setFieldValue("_language", selectedOption ? selectedOption.value : null);
                                                }}
                                                defaultValue={
                                                    props.values._language
                                                        ? {
                                                            label: languageOptions.find(option => option.value === props.values._language)?.label || '',
                                                            value: props.values._language,
                                                        }
                                                        : null
                                                }
                                                error={props.errors._language && props.touched?._language}
                                            />
                                            {props.errors._language && props.touched?._language && (
                                                <FormHelperText sx={{ color: "#f5365c", fontSize: "12px", marginTop: "6px" }}>
                                                    {props.errors._language}
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                    </Grid>
                                </ArgonBox>
                            </Card>
                            <ArgonBox p={3}>
                                <ArgonButton
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color={"info"}
                                    sx={{
                                        display: "flex",
                                        width: "124px",
                                        padding: " 10px 20px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "8px",
                                    }}
                                >
                                    {t("Update")}
                                </ArgonButton>
                            </ArgonBox>
                        </Form>
                    )}
                </Formik>
            </>
        </DashboardLayout>

    );
}

export default profile;