import React from "react";
// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import { useState } from "react";
import Header from "blikol-layouts/components/Header";
import Grid from "@mui/material/Grid";
import ClinicList from "blikol-layouts/clinics/components/List";
import RequestList from "blikol-layouts/clinics/components/Requests";
function clinics() {
  const [status, setStatus] = useState("active");
  const [pageNo, setPageNo] = useState(1)
  const handleTabChange = (selectedTab) => {
    setStatus(selectedTab.toLowerCase());
    setPageNo(1)
  };
  return (
    <DashboardLayout>
      <DashboardNavbar customRoutePath={"Clinics"} />
      <Grid container spacing={3} alignItems="center" mb={2}>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Header
            onTabChange={handleTabChange}
            numTabs={3}
            tabNames={["Active", "Inactive", "Requests"]}
          />
        </Grid>
      </Grid>
      {status === "requests" ? <RequestList setPageNo={setPageNo} pageNo={pageNo} /> : <ClinicList status={status} setPageNo={setPageNo} pageNo={pageNo} />}
    </DashboardLayout>
  );
}
export default clinics;





