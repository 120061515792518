// ResetConfirmModal.js
import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import ArgonBadge from 'components/ArgonBadge';
import ArgonBox from 'components/ArgonBox';

const ResetConfirmModal = ({ open, onClose, onConfirm }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <ArgonBox sx={{ padding: "30px" }}>
                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        color: 'var(--Text, #1A1D1F)',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: '150%',
                    }}>Reset Diagnostic
                </DialogTitle>
                <DialogContent

                    sx={{
                        textAlign: "center",
                        color: '#141416',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '150%',

                    }}
                >
                    Are you sure you want to reset?<br />
                    Resetting will remove session from the app.
                </DialogContent>
                <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                    <Button onClick={onClose}
                        variant="outlined"
                        sx={{
                            color: "#0A9CC4",
                            borderRadius: "4px",
                            border: "1px solid #0A9CC4",
                            margin: "0px 16px 0px 16px",
                            width: "fit-content",
                        }}>
                        Cancel
                    </Button>
                    <Button onClick={onConfirm}
                        variant="contained"
                        sx={{
                            color: "#FFF",
                            display: 'inline-flex',
                            padding: '10px 20px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '8px',
                            borderRadius: '4px',
                        }}
                    >
                        Reset Diagnostic
                    </Button>
                </DialogActions>
            </ArgonBox>

        </Dialog>
    );
};

ResetConfirmModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default ResetConfirmModal;
